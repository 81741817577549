.flex-container {
    display: flex;
    width: 75%;
    max-width: 50rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 75%;
    background-color: rgba(47, 43, 47, 0.75);
    padding: 1vw;
    border-radius: 2rem;
    border-top-left-radius: 17vw;
    margin: 3rem 0;
    gap: 0;
}

@media only screen and (min-width: 16.667rem) {
    .flex-container {
        border-top-left-radius: 17.5vw;
    }
}

@media only screen and (min-width: 33.333rem) {
    .flex-container {
        border-top-left-radius: 18vw;
    }
}
@media only screen and (min-width: 50rem) {
    .flex-container {
        border-top-left-radius: 18.5vw;
    }
}

@media only screen and (min-width: 66.667rem) {
    .flex-container {
        border-top-left-radius: 12.6667rem;
    }
}

.splash {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 75%;
    margin: 0;
    padding: 0;
}

.flex-container div {
    text-align: left;
}

.avatar-container {
    flex-basis: 50%;
    aspect-ratio: 1;
    overflow: hidden;
    clip-path: url(#avatar-clip);
}

.avatar {
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.hi {
    flex-basis: 50%;
    fill: white;
    margin: 1rem 1rem 0 0;
    padding: 0 0 0 2rem;
}

.hi p {
    margin: 0;
    padding: 0;
}

.profile {
    margin: 0 1rem 1rem 1rem;
}

.profile p {
    text-align: justify;
    line-height: 130%;
    margin-top: 1rem;
}