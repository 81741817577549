#start-circle-svg {
    position: absolute;
    overflow: visible;
    height: calc(min(50vw, 50vh));
    top: calc(50vh - min(25vw, 25vh) - 1.5rem);
    left: calc(50vw - min(25vw, 25vh));
    fill: white;
    opacity: 0.9;
}

.front-logo {
    transform: translateZ(0);
    overflow: visible !important;
    backface-visibility: hidden;
    perspective: 1000px;
    fill: white;
    z-index: 304;
}
.logo1 {
    z-index: 303;
    fill: #b983ff;
}
.logo2 {
    z-index: 302;
    fill: #94b3fd;
}
.logo3 {
    z-index: 301;
    fill: #94daff;
}
.logo4 {
    z-index: 300;
    fill: #99feff;
}

.logo-instance {
    height: calc(min(50vw, 50vh));
    position: absolute;
    top: calc(50vh - min(25vw, 25vh) - 1.5rem);
    left: calc(50vw - min(26vw, 26vh));
}

section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}