.menu-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 25rem;
    height: 100vh
}

#hamburger {
    position: absolute;
    top: 0;
    right: 1.5rem;
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0;
    cursor: pointer;
    height: 3rem;
    width: 4rem;
    display: flex;
    flex-direction: column;
    gap: 0.45rem;
    justify-content: center;
}

.closed-hamburger:hover .hamburger-bar {
    background-color: #0ff89a;
}

.open-hamburger:hover .hamburger-bar {
    background-color: #F7C90F;
}

.hamburger-bar {
    background-color: white;
    height: 0.2rem;
    border-radius: 0.1rem;
    margin: 0;
    transition: background-color 0.5s;
}

#menu-box {
    z-index: 300;
    position: absolute;
    top: 3rem;
    right: -20rem;
    width: 20rem;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 4rem 5.5rem 0 0;
    gap: 3rem;
    background-color: #100e10;
}

#menu-box .navlink {
    font-size: 2rem;
    opacity: 0;
    transition: 1s;
    transition-property: background-color;
}

#menu-box .navlink:hover, #menu-box .logo:hover {
    background-color: #443d44;
    transition: 0.1s;
    transition-property: background-color;
}

#menu-box .navlink:active, #menu-box .logo:active {
    color: #232123;
    fill: #232123;
    background-color: rgb(168, 155, 168);
    transition-property: color, background-color;
    transition: 0.1s;
}


#menu-box .logo {
    opacity: 0;
    height: 6rem;
    max-width: 6rem;
    margin-right: 1rem;
    padding: 1rem;
    border-radius: 50%;
}
