form, #submitted {
    font-family: 'Questrial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    width: 75%;
    margin: auto;
    padding: 1.5rem;
    min-width: 13rem;
    max-width: 53rem;
    border-radius: 1.5rem;
    color: white;
    background-color: #2F2B2FBF;
}

#submitted {
    width: 50%;
    align-items: center;
}

label {
    text-align: left;
    width: 100%;
}

input[type=text], textarea {
    font-family: 'Questrial', sans-serif;
    min-width: 10rem;
    margin-top: 0.5rem;
    border: 0;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
}

input[type=checkbox] {
    margin-right: 1rem;
}

.error {
    margin-top: 0.5rem;
    color: #efa0a0;
}

#submit {
    font-family: 'Questrial', sans-serif;
    border: 0;
    padding: 0.5rem 0.75rem;
    border-radius: 1rem;
    margin: 0 auto;
}

#submit:hover {
    background-color: #72f5bf;
}

#submit:active {
    background-color: #0ff89a;
}

h2, p {
    color: white;
}

h2 {
    font-size: 1.5rem;
}

.submission-error {
    background-color: #efa0a0;
    border-radius: 1.5rem;
    text-align: center;
    margin: auto;
}
.submission-error h3 {
    color: black;
    margin: 0.5rem 1rem;
}


textarea.errored, input.errored {
    background-color: #f57272;
}

textarea.valid, input.valid {
    background-color: #72f5bf;
}

textarea:focus, input:focus {
    outline: thin solid #0ff89a;
    background-color: white;
}
