.container {
    font-family: 'Questrial', sans-serif;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 75%;
    max-width: 75rem;
    background-color: rgba(47, 43, 47, 0.75);
    padding: 2rem;
    border-radius: 2rem;
    gap: 1.5rem;
}

.talk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 2rem;
    flex-wrap: wrap;
}

.container h3 {
    margin-bottom: 1rem;
}

.video-embed {
    flex: 2 1 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    aspect-ratio: 16/9;
    border-radius: 0.5rem;
}

.video-embed iframe,
.video-embed object,
.video-embed embed {
    width: 100%;
    aspect-ratio: 16/9;
}

.description {
    flex: 3 1 20rem;
    text-align: left;
    align-self: flex-start;
    line-height: 130%;
}

.description h3 {
    font-size: 1.25rem;
    color: #72f5bf;
    margin-bottom: 1.5rem;
}
.description ul{
    list-style-position: outside;
    padding: 0.5rem 0 0 0.5rem;
    list-style-type: '\00bb';
}
.description li {
    padding: 0.5rem 0 0 0.5rem;
}