@font-face {
  font-family: 'Questrial';
  src: url('../../fonts/questrial-regular-webfont.woff2') format('woff2'),
  url('../../fonts/questrial-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

#moon {
  width: 100%;
  height: 100%;
}

.shader-blur {
  filter: blur(0.5rem);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
  overflow: hidden;
}

.shader-backdrop {
  clip-path: url(#moon);
  height: calc(100% - 3rem);
}

.navbar {
  z-index: 201;
  top: 0;
  left: 0;
  width: 100vw;
  position: absolute;
}

.page-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 200;
}

.page {
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  min-height: calc(100vh - 3rem);
  top: 3rem;
  left: 0;
}

a {
  text-decoration: none;
  color: #72f5bf;
  transition: 1.0s;
}

a:hover {
  color: #0ff89a;
  transition: 0.1s;
}

a:active {
  color: #fde480;
  transition: 0.1s;
}
