#link-container {
    display: grid;
    overflow: visible;
    border-radius: 1.25rem;
    cursor: pointer;
}

#link-container:hover .navlink {
    color: #0ff89a;
    transition-property: color;
    transition: 0.1s;
}

#link-container:active .navlink {
    color: #232123;
    background-color: rgb(168, 155, 168);
    transition-property: color, background-color;
    transition: 0.1s;
}

#link-container .navlink {
    z-index: 301;
}

#link-bg, #link-bg-active {
    overflow: visible;
    fill: rgb(255, 223, 255);
    opacity: 0;
}