.navbar {
    font-family: 'Questrial', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2.5rem;
    align-items: center;
    flex-shrink: 0;
    color: white;
    height: 3rem;
    background-color: #232123;
}

.logo {
    fill: white;
    stroke: none;
    height: 2.5rem;
    max-width: 3.5rem;
    border-radius: 1.25rem;
    transition: 1.0s;
    vertical-align: middle;
}

.brand, .links {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: white;
}

.brand {
    justify-content: flex-start;
    gap: 2rem;
}

.brand h2 {
    margin: 0;
}

.links {
    justify-content: space-between;
    flex-basis: 50%;
    margin: 0;
    padding: 0;
}

#name {
    flex-shrink: 0;
}

.navlink {
    text-decoration: none;
    color: white;
    transition: color 1.0s;
    flex-shrink: 0;
    padding: 0.5em 1.0em;
    border-radius: 1em;
}

.navlink:hover, .logo:hover {
    color: #0ff89a;
    fill: #0ff89a;
    transition: 0.1s;
    transition-property: color, background-color, fill;
}

.navlink:active, .logo:active {
    background-color: rgba(243, 227, 243, 0.73);
    transition: 0.1s;
}

h3 {
    margin: 0;
    padding: 0;
}

.brand {
    width: 20%;
}

.github {
    height: 2.5rem;
    margin: 0;
    padding: 0;
}

.menu {
    flex-basis: 20%;
}

@media only screen and (max-width: 850px) {
    .links {
        display: none;
    }
}

@media only screen and (min-width: 850px) {
    .menu {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    #name {
        display: none;
    }
}

